import Render from '@Views/root/shop/main.html'
import { Vue, Component } from 'vue-property-decorator'
import { ContextMain } from './context-main'
import { IBreadcrumbItem, IShop, IPack } from '@Store/types'
import { PackItem } from './pack-item'
import { PackType } from '@Enums/index'
import { breadcrumb, shop } from '@Store/modules'
import { Main as MainComponent } from '../main'

@Render
@Component({
	components: {
		'context-main': ContextMain,
		'pack-item': PackItem
	}
})
export class Main extends Vue {

	//#region Getters
	get shop(): IShop {
		return shop.shop
	}
	get mainPacks(): IPack[] {
		return this.shop.packs.filter(p => { return p.id === PackType.Unlimited})
	}
	get displayCommunication(): boolean {
		return new Date() < MainComponent.limitDate
	}
	get subPacks(): IPack[] {
		let packs: IPack[] = this.shop.packs.filter(p => {
			return p.id === PackType.Sms
				|| p.id === PackType.Site
				|| p.id === PackType.Import
		})
		packs.push({
			id: PackType.Site,
			recurrent: false,
			values: []
		})
		return packs;
	}
	//#endregion

	mounted() {
		breadcrumb.updateLinked(true)
		breadcrumb.updateAside(false)
		this.initializeBreadcrumb()
	}

	private initializeBreadcrumb(): void {
		let item: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.shop').toString() }
		breadcrumb.updateItems([item])
	}
}
