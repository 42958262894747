import Render from '@Views/components/input/input-document.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import Compressor from 'compressorjs'
import Editor from '@tinymce/tinymce-vue'
import { INotification } from '@Store/types'
import { notif } from '@Store/modules'
@Render
@Component({
    components: {
        'editor': Editor
    }
})
export class InputDocument extends Vue {

    private _options: any

    @Prop({default: false}) isLight: boolean
    @Prop({default: false}) disabled: boolean
    @Prop({default: false}) readOnly: boolean
    @Prop({default: false}) isPage: boolean
    @Prop() id: string
    @Prop() placeholder: string
    @Prop() title: string
    @Prop() value: string
    @Prop() options: any
    @Prop() content: string

    static lightOptions(): any {
        return {
            plugins: 'link lists',
            toolbar: 'bold italic underline | numlist bullist | link',
            height: '17em'
        }
    }

    static superLightOptions(): any {
        return {
            plugins: 'link',
            toolbar: 'bold italic underline | link',
            height: '17em'
        }
    }

    get key(): string {
        // return "b2ikizfdqijknbpbpf29xvsz5iqsfqh733u3mh5binec8lnp"
        return "elszutdw0cb5zb0b7yvpkviok08pqiogux4g287it5ogiigb"
    }
    get selectedOptions(): any {
        return this._options
    }
    private get defaultOptions(): any {
        return {
            plugins: 'lists image code',
            menubar: false,
            toolbar: 'fontselect fontsizeselect bold italic underline forecolor backcolor alignleft aligncenter alignright alignjustify outdent indent numlist bullist removeformat customInsertButton code',
            paste_data_images: true,
            setup: this.setup,
            a11y_advanced_options: true,
            branding: false,
            height: '40em'
        }
    }

    created() {
        this.initialize()
    }

    handleInput(e: any) {
        this.$emit('input', e)
    }

    insertText(text: string): void {
        (this.$refs.editor as any).editor.insertContent(text)
    }

    private initialize(): void {
        this._options = Object.assign({}, this.defaultOptions, this.options)
    }

    private setup(editor: any): void {
        let vm = this
        editor.ui.registry.addButton('customInsertButton', {
            icon: 'edit-image',
            tooltip: 'Insert image',
            onAction: function (_) {
                let input: HTMLInputElement = document.createElement('input')
                input.setAttribute('type', 'file')
                input.setAttribute('accept', 'image/*')

                input.onchange = (evt) => {
                    var file = input.files[0]
                    vm.compressFile(editor, file)
                    // var reader = new FileReader()
                    // reader.onload = (progressEvent) => {
                    //     let fileReader: FileReader = progressEvent.srcElement as FileReader
                    //     let result: string = fileReader.result as string
                    //     editor.insertContent(`<img src="${result}">`)
                    // }
                    // reader.readAsDataURL(file)
                }

                input.click()
            }
        })
    }

    private compressFile(editor: any, file: File) {
		new Compressor(file, {
			quality: 0.8,
			convertSize: 2000000,
			maxWidth: 1400,
			maxHeight: 1400,
			success(imageBlob) {
				const reader = new FileReader()
				reader.readAsDataURL(imageBlob)
				reader.onload = () => {
                    let result: string = reader.result as string
                    editor.insertContent(`<img src="${result}">`)
                }
			},
			error(err) {
				let notification: INotification = {message: err.message, actions:[], delay: 5000, canClose: true}
				notif.error(notification)
			},
		})
	}
}
