<div>
	<div class="p-like">
		<span class="text-primary text-bold" :title="title">{{title}}</span>
	</div>
	<div class="p-like details">
		<span>
			<strong>{{ $t('vm.root.shop.invoice-item.subscription-date') }}</strong>
			{{ formattedDate }}
		</span>
	</div>
	<div class="p-like childs-space-between">
			<a
				v-if="isPaid"
				:href="invoice.invoice_url"
				target="_blank"
				rel="noopener noreferrer"
				class="btn btn-primary"
				:title="$t('vm.root.shop.invoice-item.invoice-download-title')"
			>
				{{ $t('vm.root.shop.invoice-item.invoice-download-text') }}
			</a>
			<strong v-else-if="isInProgress" class="text-warning">{{ $t('vm.root.shop.invoice-item.in-progress') }}</strong>
			<strong v-else class="text-danger">{{ $t('vm.root.shop.invoice-item.unpaid') }}</strong>
	</div>
</div>
