import Render from '@Views/components/popin/popin.html'
import { Vue, Component } from 'vue-property-decorator'
import * as PopinLayout from './layout/index'
import {PopinType} from '@Enums/index'
import { popIn } from '@Store/modules'
import { IPopin, IPopinAction } from '@Store/types'
import {IDropdownActionItem} from "@ViewModels/components"

@Render
@Component({
    components: {
        'popin-component': PopinLayout.PopinComponent,
        'popin-duplicate-event': PopinLayout.PopinDuplicateEvent,
        'popin-create-colleague': PopinLayout.PopinCreateColleague,
        'popin-search-colleague': PopinLayout.PopinSearchColleague,
        'popin-search-patient': PopinLayout.PopinSearchPatient,
        'popin-create-patient': PopinLayout.PopinCreatePatient,
        'popin-rgpd-patient': PopinLayout.PopinRgpdPatient,
        'popin-search-member': PopinLayout.PopinSearchMember,
        'popin-sticky-note-share': PopinLayout.PopinStickyNoteShare,
        'popin-refuse-event': PopinLayout.PopinRefuseEvent,
        'popin-create-receipt': PopinLayout.PopinCreateReceipt,
        'popin-unpaid-invoice': PopinLayout.PopinUnpaidInvoice,
        'popin-create-antecedent': PopinLayout.PopinCreateAntecedent,
        'popin-create-sphere': PopinLayout.PopinCreateSphere,
        'popin-print-patient': PopinLayout.PopinPrintPatient,
        'popin-share-referral': PopinLayout.PopinShareReferral,
        'popin-change-log': PopinLayout.PopinChangeLog,
        'popin-communication':PopinLayout.PopinCommunication,
        'popin-subscription':PopinLayout.PopinSubscription,
        'popin-medsmart': PopinLayout.PopinMedSmart
    }
})
export class Popin extends Vue {

    loading: boolean = false
    submitted: boolean = false
    title: string = null
    component: string = null

    get type(): PopinType {
		return popIn.type
    }
    get popin(): IPopin {
		return popIn.popin
    }
    get options(): any {
		return popIn.options
    }
    get action(): IPopinAction {
		return popIn.popin.action
    }
    get actions(): IPopinAction[] {
		return popIn.popin.actions
    }
	get actionItems(): IDropdownActionItem[] {
		let result: IDropdownActionItem[] = []
		let id: number = 0;
		for (let action of this.actions) {
			result.push({
				value: id++,
				icon: action.icon,
				action: () => {
					this.submitted = true
					action.callback(popIn.options, this.updateLoading)
						.then(() => {
							this.submitted = false
							if (!!action.stayOpen) return
							popIn.clear()
						})
				},
				label: action.label,
				title: action.title
			})
		}
		return result
	}
	get hasComponent(): boolean {
        return typeof this.popin.content !== 'string'
    }
	get cantCancel(): boolean {
		return !!popIn.popin.cantCancel
	}
	get cancelLabel(): string {
		return popIn.popin.cancelLabel || this.$i18n.t('general.cancel.text').toString()
	}
    private get cancelAction(): () => any {
		return popIn.popin.cancelAction
    }

    display(): boolean {
		return this.type !== PopinType.None && !!this.popin
	}

	close(): void {
        if (!!this.cancelAction) {
            this.cancelAction()
        }
        this.submitted = false
		popIn.clear()
    }

    callAction(): void {
        this.submitted = true
        this.action.callback(popIn.options, this.updateLoading)
        .then(() => {
            this.submitted = false
            popIn.clear()
        })
    }

    canCallAction(): boolean {
        if (!this.action.condition) return true
        if (this.action.condition(popIn.options)) return true

        return false
    }

    canCallActions(): boolean {
        if (!this.actions[0].condition) return true
        if (this.actions[0].condition(popIn.options)) return true

        return false
    }

    buttonClass(): any {
        switch(this.type) {
            case PopinType.Error:
                return 'btn-danger'
            case PopinType.Warning:
                return 'btn-warning'
            case PopinType.Success:
                return 'btn-success'
            case PopinType.Info:
                return 'btn-info'
            default:
                return 'btn-default'
        }
    }

    popinClass(): any {
        let popinClass:string = this.popin.isWide ? 'is-wide ' : ''
        switch(this.type) {
            case PopinType.Error:
                return `${popinClass}is-danger`
            case PopinType.Warning:
                return `${popinClass}is-warning`
            case PopinType.Success:
                return `${popinClass}is-success`
            case PopinType.Info:
                return `${popinClass}is-info`
            default:
                return `${popinClass}is-default`
        }
    }

    private updateLoading(loading: boolean): void {
        this.loading = loading
    }
}
