import { getModule, VuexModule, Module, Action, Mutation } from "vuex-module-decorators"
import { store } from '@Store/index'
import { shop } from '@Store/modules'
import { IPurchase, IPack } from "@Store/types"
import { PackType, PurchaseState, RequestStatus } from "@Enums/index"
import { PurchaseService } from "@Services/index"

@Module({
	name: 'purchase',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
class PurchaseModule extends VuexModule {
    purchase: IPurchase = null
    private status: RequestStatus = RequestStatus.None
    private statusDeleting: RequestStatus = RequestStatus.None
    private statusUnsubscribing: RequestStatus = RequestStatus.None
    private statusSaving: RequestStatus = RequestStatus.None
    private statusApplying: RequestStatus = RequestStatus.None
    private purchaseDeleting: string = null

	get isDeleting(): (pur_id?: string) => boolean {
		return (pur_id?: string) => {
			return this.statusDeleting === RequestStatus.Loading && (!pur_id || this.purchaseDeleting === pur_id)
		}
	}
	get isUnsubscribing(): (pur_id?: string) => boolean {
		return (pur_id?: string) => {
			return this.statusUnsubscribing === RequestStatus.Loading && (!pur_id || this.purchaseDeleting === pur_id)
		}
	}
	get pack():(type: PackType) => IPack {
		return (type: PackType) => {
			return shop.shop.packs.find(p => { return p.id === type })
		}
	}
	get isCompleted(): (state: PurchaseState) => boolean {
		return (state: PurchaseState) => {
			return state === PurchaseState.Completed
		}
	}
	get isLoaded(): boolean {
		return this.status === RequestStatus.Success
	}
	get isLoading(): boolean {
		return this.status === RequestStatus.Loading
	}
	get isSaving(): boolean {
		return this.statusSaving === RequestStatus.Loading
	}
	get isApplying(): boolean {
		return this.statusApplying === RequestStatus.Loading
	}

	@Mutation
	clear() {
		this.status = RequestStatus.None
		this.statusDeleting = RequestStatus.None
		this.statusUnsubscribing = RequestStatus.None
		this.statusSaving = RequestStatus.None
		this.statusApplying = RequestStatus.None
		this.purchase = null
		this.purchaseDeleting = null
	}

	@Mutation
	purchaseRequest() {
		this.status = RequestStatus.Loading
		this.purchase = null
	}

	@Mutation
	purchaseSuccess(purchase: IPurchase) {
		this.status = RequestStatus.Success
		this.purchase = purchase
	}

	@Mutation
	purchaseFailure() {
		this.status = RequestStatus.Failed
	}

	@Mutation
	deleteRequest(pur_id: string) {
		this.purchaseDeleting = pur_id
		this.statusDeleting = RequestStatus.Loading
	}

	@Mutation
	deleteSuccess(pur_id: string) {
		this.purchaseDeleting = null
		this.statusDeleting = RequestStatus.Success
	}

	@Mutation
	deleteFailure() {
		this.purchaseDeleting = null
		this.statusDeleting = RequestStatus.Failed
	}

	@Mutation
	unsubscribeRequest(pur_id: string) {
		this.purchaseDeleting = pur_id
		this.statusUnsubscribing = RequestStatus.Loading
	}

	@Mutation
	unsubscribeSuccess(pur_id: string) {
		this.purchaseDeleting = null
		this.statusUnsubscribing = RequestStatus.Success
	}

	@Mutation
	unsubscribeFailure() {
		this.purchaseDeleting = null
		this.statusUnsubscribing = RequestStatus.Failed
	}

	@Mutation
	saveRequest() {
		this.statusSaving = RequestStatus.Loading
	}

	@Mutation
	saveSuccess(purchase: IPurchase) {
		this.statusSaving = RequestStatus.Success
		this.purchase = purchase
	}

	@Mutation
	saveFailure() {
		this.statusSaving = RequestStatus.Failed
	}

	@Mutation
	codeRequest() {
		this.statusApplying = RequestStatus.Loading
	}

	@Mutation
	codeSuccess() {
		this.statusApplying = RequestStatus.Success
	}

	@Mutation
	codeFailure() {
		this.statusApplying = RequestStatus.Failed
	}

	@Action({rawError: true})
	async loadPurchase(pur_id: string): Promise<any> {
		if (this.status === RequestStatus.Loading) return

		this.purchaseRequest()

		let service = new PurchaseService()
		return service.getPurchase(pur_id)
		.then(
			purchase => {
				this.purchaseSuccess(purchase)
				return Promise.resolve(purchase)
			}
		)
		.catch(error => {
			this.purchaseFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async deletePurchase(pur_id: string): Promise<any> {
		if (this.statusDeleting === RequestStatus.Loading) return

		this.deleteRequest(pur_id)

		let service = new PurchaseService()
		return service.deletePurchase(pur_id)
		.then(() => {
			this.deleteSuccess(pur_id)
			return Promise.resolve()
		})
		.catch(error => {
			this.deleteFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async unsubscribePurchase(pur_id: string): Promise<any> {
		if (this.statusUnsubscribing === RequestStatus.Loading) return

		this.unsubscribeRequest(pur_id)

		let service = new PurchaseService()
		return service.unsubscribePurchase(pur_id)
		.then(() => {
			this.unsubscribeSuccess(pur_id)
			return Promise.resolve()
		})
		.catch(error => {
			this.unsubscribeFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async createCheckout(payload: { value: number, type: string }): Promise<any> {
		if (this.statusSaving === RequestStatus.Loading) return

		this.saveRequest()

		let service = new PurchaseService()
		return service.createCheckout(payload)
			.then(({url}) => {
				this.saveSuccess(null)
				return Promise.resolve(url)
			})
			.catch(error => {
				this.saveFailure()
				return Promise.reject(error)
			})
	}

	@Action({rawError: true})
	async savePurchase(purchase: IPurchase): Promise<any> {
		if (this.statusSaving === RequestStatus.Loading) return

		this.saveRequest()

		let service = new PurchaseService()
		return service.save(purchase)
		.then(newPurchase => {
			this.saveSuccess(newPurchase)
			return Promise.resolve(newPurchase)
		})
		.catch(error => {
			this.saveFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async applyCode(payload: {code: string, purchase: IPurchase}): Promise<any> {
		if (this.statusApplying === RequestStatus.Loading) return

		this.codeRequest()

		let service = new PurchaseService()
		return service.applyCode(payload.code, payload.purchase)
		.then(result => {
			this.codeSuccess()
			return Promise.resolve(result)
		})
		.catch(error => {
			this.codeFailure()
			return Promise.reject(error)
		})
	}
}

export const purchase = getModule(PurchaseModule)
// export const shop = new ShopModule({ store, name: "shop" })
