<form @submit.prevent="submitSave">

	<router-view :user="user" :submitted="submitted"/>

	<control-bar>
		<div class="childs-space-between">
			<button-action btn-class="btn-default" @click.native="submitCancel" :disabled="disabled"
				:title="$t('vm.root.user.account.cancel.title')">
				<svg width="1em" class="svg-middle" viewBox="0 0 320 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#times"/>
				</svg>
				{{ $t('vm.root.user.account.cancel.label') }}
			</button-action>

			<button-action type="submit" btn-class="btn-primary" :loading="isLoading" :disabled="disabled"
				:title="$t('vm.root.user.account.update.title')">
				{{ $t('vm.root.user.account.update.short-label') }}
				<span class="rwd-over-xlarge">{{ $t('vm.root.user.account.update.long-label') }}</span>
				<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true">
					<use xlink:href="@Assets/svg/symbol-defs.svg#check"/>
				</svg>
			</button-action>
		</div>
	</control-bar>
</form>
