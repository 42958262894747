export { BaseService } from './base.service'
export { ErrorService } from './error.service'
export { LoginService } from './login.service'
export { AddressService } from './address.service'
export { TagService, TagCategory } from './tag.service'
export { CampaignService } from './patient/campaign.service'
export { ColleagueService } from './patient/colleague.service'
export { UserService } from './user/user.service'
export { DocumentService } from "./user/document.service"
export { OfficeService } from './user/office.service'
export { AccountService } from './user/account.service'
export { ConfigurationService } from './user/configuration.service'
export { EventService } from './planning/event.service'
export { PlanningService } from './planning/planning.service'
export { GoogleService } from './planning/google.service'
export { BulkService } from './shop/bulk.service'
export { PurchaseService } from './shop/purchase.service'
export { ReferralService } from './shop/referral.service'
export { BlogService } from './home/blog.service'
export { WidgetService } from './home/widget.service'
export { StickyNoteService } from './home/sticky-note.service'
export { AccountingService } from './accounting/accounting.service'
export { SupportService } from './support/support.service'
export { CustomerService } from './patient/customer.service'
export { PatientService } from './patient/patient.service'
export { HistoricService } from './patient/historic.service'
export { StatisticsService } from "./statistics/statistics.service"
export { GravatarService } from './gravatar.service'
export { MedsmartService } from './medsmart.service'
