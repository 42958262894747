import { BaseService } from '../base.service'
import sha256 from 'sha-256-js'
import { StorageHelper } from '@Helpers/index'
import { Gift3Payload, Gift2Payload } from '@Store/types'

export class UserService extends BaseService {
	public async update(user: any): Promise<any> {
		return super.put('/user', user)
		.then(data => { return Promise.resolve(data) })
		.catch((error: any) => {
			let message = "Erreur durant la mise à jour."
			if (!error) return Promise.reject(message)

			let errorDescription = !!error.data ? error.data.error_description : ""
			switch(error.status) {
				case 412:
					if (errorDescription === 'siren') {
						message = "Vous devez saisir un SIREN valide."
					} else {
						message = "Vous devez indiquer vos noms et prénoms."
					}
					break
				case 409:
					message = "Ce SIREN est déjà utilisé par un ostéopathe."
					break
				case 401:
					message = "Vous avez été déconnecté !"
					StorageHelper.set('error', message)
					break
			}
			return Promise.reject(message)
		})
	}

	public async password(currentPassword: string, newPassword: string, confirmPassword: string): Promise<any> {
		return super.put('/user/password', {old_password: sha256(currentPassword), new_password1: sha256(newPassword), new_password2: sha256(confirmPassword)})
		.then(data => { return Promise.resolve(data) })
		.catch((error: any) => {
			let message = "Erreur durant la mise à jour."
			if (!error) return Promise.reject(message)

			switch(error.status) {
				case 412:
					if (error.statusText === 'old') {
						message = "Vous devez indiquer votre ancien mot de passe."
					} else if (error.statusText === 'empty') {
						message = "Votre nouveau mot de passe ne peut être vide."
					} else {
						message = "Vous devez confimer votre nouveau mot de passe."
					}
					break
				case 401:
					message = "Vous avez été déconnecté !"
					StorageHelper.set('error', message)
					break
			}
			return Promise.reject(message)
		})
	}

	public async email(newEmail: string, confirmEmail: string): Promise<any> {
		return super.put('/user/email', {email: newEmail, confirm_email: confirmEmail})
		.then(data => { return Promise.resolve(data) })
		.catch((error: any) => {
			let message = "Erreur durant la mise à jour."
			if (!error) return Promise.reject(message)

			switch(error.status) {
				case 412:
					if (error.statusText === 'same') {
						message = "Vous devez confirmer votre nouvelle adresse email."
					} else if (error.statusText === 'invalid') {
						message = "Vous devez saisir une adresse email valide."
					} else {
						message = "Vous devez indiquer votre nouvelle adresse email."
					}
					break
				case 409:
					message = "Cette adresse email est déjà utilisée par un ostéopathe."
					break
				case 401:
					message = "Vous avez été déconnecté !"
					StorageHelper.set('error', message)
					break
			}
			return Promise.reject(message)
		})
	}

	public async export(): Promise<any> {
		return super.specificGet('/csv/database/')
	}

	public async getGift(): Promise<any> {
		return super.get('/gift')
	}

	public async getStorage(): Promise<any> {
		return super.get('/user/storage')
	}

	public async updateGift1(): Promise<any> {
		return super.put('/user/gift1/')
	}

	public async updateGift2(payload: Gift2Payload): Promise<any> {
		return super.put('/user/gift2/', payload)
	}

	public async updateGift3(payload: Gift3Payload): Promise<any> {
		return super.put('/user/gift3/', payload)
	}

	public async leave(): Promise<any> {
		return super.post('/user/leave')
	}
}
