export const informations = {
    gender: {
        label: "Sexe",
        man: "homme",
        woman: "femme",
        other: "autre"
    },
    'main-title': "Informations générales",
    title: {
        label: "Titre",
        placeholder: "ex : Ostéopathe D.O.",
        title: "Veuillez renseigner votre titre"
    },
    'last-name': {
        label: "Nom",
        'error-msg': "Merci de renseigner votre nom",
        placeholder: "ex : Still",
        title: "Veuillez renseigner votre nom"
    },
    'first-name': {
        label: "Prénom",
        'error-msg': "Merci de renseigner votre prénom",
        placeholder: "ex : Andrew Taylor",
        title: "Veuillez renseigner votre prénom"
    },
    siren: {
        label: "SIREN",
        'error-msg': "Merci de renseigner un SIREN valide",
        placeholder: "ex : 803473990",
        title: "Veuillez renseigner votre SIREN"
    },
    adeli: {
        label: "Numéro ADELI",
		'error-msg': "Merci de renseigner un numéro ADELI valide",
        placeholder: "ex : 350400037",
        title: "Veuillez renseigner votre numéro ADELI"
    },
	transmit: {
		label: "activer la télétransmission",
		title: "Télétransmission pour votre compte",
		'active-label': "télétransmission activée",
		'medsmart-actived': "Votre compte MedSmart est déjà activé, il n'est donc pas nécessaire de le réactiver."
	},
	rpps: {
		label: "Numéro RPPS",
		placeholder: "ex : 100190453281",
		title: "Veuillez renseigner votre numéro RPPS"
	},
    'your-avatar': "Votre avatar",
    avatar: {
        title: "Gravatar",
        label: "Changer d'avatar"
    }
}
