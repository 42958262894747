import Render from '@Views/components/popin/layout/popin-medsmart.html'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Render
@Component({})
export class PopinMedSmart extends Vue {

    @Prop() options: {url: string}
	@Prop({default: false}) submitted: boolean

	get url(): string {
		return this.options.url
	}

	frameLoaded(event: CustomEvent): void {
		const iframe = event.target as HTMLIFrameElement
		console.log('fromMedSmart', iframe.contentWindow.location, this.url)
		console.log('fromMedSmart', iframe.src)
		// if (!iframe || this.url === iframe.contentWindow.document.location.href) return
		//
		event.preventDefault()
		// window.location.href = iframe.contentWindow.document.location.href
	}
}
