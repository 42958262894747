export const invoiceManager = {
    invoice: "Facture",
	'update-invoice': {
        title: "Modifiez la facture liée à cette consultation",
        label: "Modifier la facture",
        'action-label': "modifier",
        'action-title': "Modifier la facture de cette consultation",
        succeeded: "La facture a bien été modifiée.",
        failed: "Cette facture est associée aux finances d'un autre utilisateur, vous ne pouvez donc modifier que les informations non essentielles à celle-ci.",
    },
    'generate-invoice': {
        label: "Générer la facture",
        title: "Générez la facture liée à cette consultation",
        'action-label': "générer",
        'action-title': "Générer la facture de cette consultation",
        succeeded: "La facture a bien été générée.",
    },
    'print-invoice': {
		'label-short': "imprimer",
		label: "Imprimer la facture",
		title: "Imprimez la facture liée à cette consultation"
	},
	'send-invoice': {
        'label-short': "envoyer",
        label: "Envoyer la facture",
        title: "Envoyez la facture par email",
        succeeded: "La facture a bien été envoyée à l'adresse <strong>{email}</strong>.",
        failed: "La facture n'a pu être envoyée à votre patient."
	},
	'transmit-invoice': {
        'label-short': "télétransmettre",
        label: "Télétransmettre la facture",
        title: "Télétransmettez la facture directement à la mutuelle",
        succeeded: "La facture a bien été envoyée à MedSmart pour télétransmission, en cas d'échec, votre patient recevra sa facture par email.",
        failed: "La facture n'a pu être télétransmise à la mutuelle du patient."
    }
}
