<transition name="fade">
	<div class="popin" v-if="display()" aria-labelledby="dialogTitle" aria-describedby="dialogDescription" role="dialog">

		<section class="popin-frame" :class="popinClass()" @keydown.esc="close">

			<header class="popin-frame-title">
				<h2 id="dialogTitle" class="h-reset">{{popin.title}}</h2>
			</header>
			<div class="popin-frame-content">

				<div id="dialogDescription" class="popin-frame-content-inner wrapper">
					<component v-if="hasComponent" :is="popin.content.component" :options="options" :submitted="submitted"></component>
					<p v-else v-html="popin.content"></p>
				</div>

				<footer class="popin-frame-footer childs-space-between">
					<button-action @click.native="close" :title="$t('general.cancel.title')" :disabled="loading" v-if="!cantCancel">
						<svg width="1em" class="svg-middle" viewBox="0 0 320 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#times"/>
						</svg>
						{{ cancelLabel }}
					</button-action>
					<span v-else><!-- pour laisser les éléments suivants à droite de la popin --></span>
					<dropdown-actions v-if="!!actions" :actions="actionItems" :disabled="!canCallActions()" :menu-on-top="true"/>
					<button-action v-else-if="!!action" :btn-class="buttonClass()" :loading="loading" :disabled="!canCallAction()"
								   :title="action.title" @click.native="callAction">
						{{action.label}}
						<svg width="1em" class="svg-middle" viewBox="0 0 512 512" aria-hidden="true" focusable="false">
							<use xlink:href="@Assets/svg/symbol-defs.svg#check"/>
						</svg>
					</button-action>
				</footer>

			</div>

		</section>

	</div>
</transition>
