export enum PurchaseState {
	Running		= 0,
	Completed	= 1
}

export enum PurchaseMode {
	Paypal		= 0,
	Check		= 1,
	Transfer	= 2
}

export enum PackType {
	Unlimited 		= 1,
	Subscription 	= 2,
	Support			= 3,
	Sms				= 4,
	Import			= 5,
	Planning		= 6,
	Card			= 7,
	Bulk1			= 8,
	Bulk2			= 9,
	Site			= 0,
}

export enum InvoiceState {
	Paid		= 'paid',
	Unpaid	    = 'unpaid',
	Inprogress  = 'inprogress'
}
export enum InvoiceMode {
	Subscription		= 'subscription',
	Payment	            = 'payment',
}
