import Render from '@Views/root/main.html'
import { Vue, Component } from 'vue-property-decorator'
import { Navigation, Header } from '@Components/index'
import {Queue, Connection} from '@Models/index'
import {NotificationType, PackType, Profile} from '@Enums/index'
import { notif, breadcrumb, user, patient, purchase, popIn } from '@Store/modules'
import {INotification, IPopin, IPopinAction, IUser} from '@Store/types'
import { StorageHelper } from '@Helpers/storage'
import {EnumsHelper} from "@Helpers/enums"
import axios from "axios";

@Render
@Component({
	components: {
		'navigation': Navigation,
		'headeur': Header
	}
})
export class Main extends Vue {

	// TODO Cheikh: tu décommentes les 2 dates suivantes en les ajustant selon tes tests et tu commentes les 2 dates suivantes
	// public static readonly limitDate: Date = new Date(2024, 9, 7, 0, 0, 0, 0)
	// private static readonly endDate: Date = new Date(2024, 9, 12, 0, 0, 0, 0)
	public static readonly limitDate: Date = new Date(2024, 10, 1, 0, 0, 0, 0)
	private static readonly endDate: Date = new Date(2025, 0, 1, 0, 0, 0, 0)

	get hasAside(): boolean {
		return breadcrumb.hasAside
	}
	get user(): IUser {
		return user.user
	}
	get communicationKey(): string {
		return `communication_key_${user.currentUser.id}`
	}
	get subscriptionKey(): string {
		return `subscription_key_${user.currentUser.id}`
	}
	get webhookKey(): string {
		return `webhook_key_${user.currentUser.id}`
	}
	get canShowCommunication(): boolean {
		let isCommunicationHidden = !!StorageHelper.get(this.communicationKey, false);
		let now = new Date();
		return !isCommunicationHidden && now < Main.limitDate;
	}
	get canShowSubscription(): boolean {
		if (this.user.license !== 99999)
			return false

		let webhookString = StorageHelper.get(this.webhookKey, '')
		if (webhookString !== '') {
			let delta = new Date()
			delta.setMinutes(delta.getMinutes() - 5)
			let webhook = new Date(webhookString)
			if (webhook > delta) return false
		}

		let middleString = StorageHelper.get(this.subscriptionKey, '')
		let middle = middleString ? new Date(middleString) : new Date()
		let now = new Date()
		return (now >= Main.limitDate && middle <= now) || now >= Main.endDate
	}

	mounted() {
		patient.loadBackup()
		.then(result => {
			if (Object.keys(result).length === 0) return

			let message:string = this.$t('vm.root.main.load-backup').toString()
			let notification:INotification = { message: message, actions: [], delay: 5000, canClose: true }
			notif.info(notification)
		})
		Queue.getInstance().addListener(this.callback)
		Connection.getInstance().addListener(this.callback)

		window.addEventListener('online',  this.updateOnlineStatus)
		window.addEventListener('offline', this.updateOnlineStatus)

		if (!this.user || this.user.profile !== Profile.Main)
			return

		if (this.canShowCommunication) {
			this.communication()
		} else if (this.canShowSubscription) {
			this.subscription()
		}
	}

	beforeDestroy() {
		Queue.getInstance().removeListener(this.callback)
		Connection.getInstance().removeListener(this.callback)

		window.removeEventListener('online',  this.updateOnlineStatus)
		window.removeEventListener('offline', this.updateOnlineStatus)
	}

	private updateOnlineStatus() {
		Connection.getInstance().state = window.navigator.onLine
	}

	private callback(notification: INotification, type: NotificationType): void {
		notif.notif({notification: notification, type: type})
	}

	 private communication(): void {

		 let communication: IPopin = {
			 title: this.$i18n.t('vm.root.home.communication.title').toString(),
			 content: {component: 'popin-communication'},
			 action: null,
			 isWide: true,
			 options: {user : this.user, storageKey : this.communicationKey},
			 cancelLabel: this.$i18n.t('general.close.text').toString()
		 }

		 popIn.info(communication)
	 }

	 private subscription(): void {
		 let now = new Date();
		let action: IPopinAction = {
			icon: 'mdi-email',
			label: this.$i18n.t('vm.root.home.subscription.action.label').toString(),
			title: this.$i18n.t('vm.root.home.subscription.action.title').toString(),
			condition: ({choice, subscription, cgv}: {user: IUser, choice: string, subscription: string, cgv: boolean}) => {
				if (choice === 'choice-1') {
					return !!subscription
				}
				return choice === 'choice-2' && cgv
			},
			callback: ({choice, subscription}: {user: IUser, choice: string, subscription: string}) => {
				if (choice === 'choice-1') {
					return this.applyChoice1(parseInt(subscription))
				}

				if (choice === 'choice-2') {
					return this.applyChoice2()
				}
				return Promise.resolve()
			},
			stayOpen: false
		}
		 let subscription: IPopin = {
			 title: this.$i18n.t('vm.root.home.subscription.title').toString(),
			 content: {component: 'popin-subscription'},
			 action,
			 isWide: true,
			 options: {user : this.user, choice : '', subscription: '', cgv: false},
			 cantCancel: now > Main.endDate,
			 cancelAction: this.noChoiceAction,
			 cancelLabel: this.$i18n.t('vm.root.home.subscription.no-choice').toString()
		 }

		 popIn.info(subscription)
	 }

	 private applyChoice1(value: number): Promise<void> {
		 const payload = {
			 value,
			 type: EnumsHelper.packTypeToStripeMeta(PackType.Unlimited)
		 }

		 return purchase.createCheckout(payload)
			 .then((url: string) => {
				 const now = new Date()
				 StorageHelper.set(this.webhookKey, now.toISOString());
				 window.location.href = url
				 return Promise.resolve()
			 })
			 .catch((error) => {
				 return Promise.reject(error)
			 })
	 }

	 private applyChoice2(): Promise<void> {
		 const email = this.user.email
		 const baseURL = 'https://confirm.matiscode.fr'
		 const provider: any = axios.create({baseURL})

		 provider.get('/leaved-client', {params: {email}})

		 return user.leave()
	 }

	private noChoiceAction(): void {
		let now = new Date()
		if (now < Main.endDate) {
			let middle = new Date(Main.endDate.getTime() - now.getTime())
			now.setTime(now.getTime() + middle.getTime() / 2)
		} else {
			now = Main.endDate
		}

		StorageHelper.set(this.subscriptionKey, `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`);
	 }
}
