import { BaseService } from '@Services/index'
import { IPurchase } from '@Store/types'

export class PurchaseService extends BaseService {
	public applyCode(code: string, purchase: IPurchase): Promise<any> {
		return super.post(`/promo/${code}`, purchase)
	}

	public getShop(): Promise<any> {
		return super.get('/shop/')
	}

	public getPurchaseList(page: number): Promise<any> {
		return super.get(`/purchases/${page}/`)
	}

	public getPurchase(pur_id: string): Promise<any> {
		return super.get(`/purchase/${pur_id}`)
	}

	public deletePurchase(purId: string): Promise<any> {
		return super.delete(`/purchase/${purId}`)
	}

	public unsubscribePurchase(purId: string): Promise<any> {
		return super.delete(`/unsubscribe/purchase/${purId}`)
	}

	public save(purchase: IPurchase): Promise<any> {
		if (parseInt(purchase.id) === -1)
			return super.post('/purchase', purchase)

		return super.put(`/purchase/${purchase.id}`, purchase)
	}

	public createCheckout(payload: { value: number, type: string }): Promise<any> {
		return super.post('/stripe-checkout', {purchase:{TYPE: payload.type, VALUE: payload.value}})
	}

}
