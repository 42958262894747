export const aside = {
    'back-patient': {
        text: "Retour aux patients",
        title: "Retournez à votre liste de patient"
    },
    'back-consultation': {
        text: "Retour au dossier",
        title: "Retournez à la liste des consultations"
    },
    examens: {
        text: "Examens",
        title: "Examens effectués durant la consultation"
    },
    'exclusion-test': {
        text: "Test d'exclusion | Test d'exclusion | Tests d'exclusion",
        title: "Test d'exclusion effectué durant la consultation | Test d'exclusion effectué durant la consultation | Tests d'exclusion effectués durant la consultation"
    },
    'cranio-sacral': {
        text: "Cranio-sacré",
        title: "Les informations liées au cranio-sacré du patient"
    },
    forms: {
        text: "Questionnaire personnalisé | Questionnaire personnalisé | Questionnaires personnalisés",
        title:"Questionnaire personnalisé de la consultation | Questionnaire personnalisé de la consultation | Questionnaires personnalisés de la consultation"
    },
    reason: {
        text: "Historique",
        title:"Historique du motif de consultation"
    },
    walton: {
        text: "Diagramme de Walton",
        title:"Diagramme de Walton de la consultation"
    },
    schema: {
        text: "Schéma corporel",
        title:"Schéma corporel de la consultation"
    },
    'follow-up': {
        text: "Suivi",
        title:"Suivi de la consultation"
    },
    delete: {
        text: "Supprimer cette consultation",
        title: "Supprimer cette consultation du dossier patient",
        message: "Voulez-vous vraiment supprimer cette consultation ?"
    },
    disabled: "Pour générer cette facture, le dossier du patient doit être sauvegardé.",
	'invoice-transmit-processing': "Votre facture a été transmise avec succès et est en cours de validation.",
	'invoice-transmit-success': "Votre facture a été transmise avec succès",
	'patient-active-transmit': {
		label: "Activer le dossier patient",
		description: "Avant de télétransmettre cette facture, vous devez activer la télétransmission pour <strong>ce patient</strong>."
	},
	'user-active-transmit': {
		label: "Activer votre compte",
		description: "Avant de télétransmettre cette facture, vous devez activer la télétransmission pour <strong>votre compte</strong>."
	}
}
