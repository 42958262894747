export const general = {
    yes: "oui",
    no: "non",
    positive: "positif",
    negative: "négatif",
    without: "sans",
    with: "avec",
    running: "en cours",
    during: "pendant",
    from: "du",
    to: "au",
    at: "à",
    have: "a",
    the: "le",
    for: "pour",
    today: "aujourd'hui",
    yesterday: "hier",
    'all-offices': "Tous les cabinets",
    'all-users': "Tous les utilisateurs",
    created: "créé | créé | créés",
	'created-female': "créée | créée | créées",
	updated: "créé | créé | créés",
    'updated-female': "créée | créée | créées",
    mister: {
        short: "M.",
        pronoun: "il"
    },
    madam: {
        short: "Mme.",
        pronoun: "elle"
    },
    print: {
        title: "Imprimer",
        text: "imprimer"
    },
    send: "envoyer",
    transmit: "télétransmettre",
    generate: "générer",
    save: {
        text: 'sauvegarder',
        title: "Sauvegarder cet élément"
    },
    delete: {
        text: 'effacer',
        title: "Effacer cet élément"
    },
    suppress: {
        text: 'supprimer',
        title: "Supprimer cet élément"
    },
    edit: {
        text: 'modifier',
        title: "Modifier cet élément"
    },
    cancel: {
        text: "annuler",
        title: "Annuler cette action"
    },
	close: {
		text: "fermer",
		title: "Fermer"
	},
    'recurrency-warning': {
        continue: "continuer",
        cancel: "annuler",
        message: "Si vous modifiez cette récurrence, <strong>tous les éléments futurs</strong> de la série seront modifiés. Voulez-vous continuer ?"
    }
}
