import Render from '@Views/root/patients/patient/documents.html'
import config from "@Config/index"
import { Component, Prop } from 'vue-property-decorator'
import {IConsultation, IBreadcrumbItem, IConfiguration, INotification, INotificationAction, IPopin} from '@Store/types'
import { IDropdownItem, InputRangeType } from '@Components/index'
import { Patient, Common } from '@Models/index'
import { DateHelper } from '@Helpers/index'
import { TranslateResult } from 'vue-i18n'
import { IInvoiceAction, InvoiceManager } from '@Managers/index'
import {breadcrumb, configuration, patient, notif, user, popIn} from '@Store/modules'

@Render
@Component({})
export class Documents extends InvoiceManager {

	con_id: string = '-1'
	private start: Date
	private end: Date

	@Prop({}) disabled: boolean

	// @State('configuration') stateConfiguration: ConfigurationState
	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Getter('patient/owner') owner: (item: {id: string, creator_id: string}) => string

	constructor() {
		super()
	}

	//#region Getters
	get configuration(): IConfiguration {
		return configuration.configuration
	}
	get hasInvoice(): boolean {
		let invId: string = this.getInvoiceId(this.consultation)
		return !!invId && parseInt(invId) !== -1
	}
	// get invoicePath(): string {
	// 	let invId: string = this.getInvoiceId(this.consultation)
	// 	return this.getInvoicePath(invId)
	// }
	get invoiceNumber(): string {
		return this.getInvoiceNumber(this.consultation)
	}
    get consultationItems(): IDropdownItem[] {
		let today: Date = new Date()
		let yesterday: Date = new Date()
		yesterday.setDate(yesterday.getDate() - 1)

		return this.consultations
		.filter(c => this.isManaged(c))
		.map(c => {
			let owner: string = this.owner(c)
			let yourself: string = this.$i18n.t('vm.root.patients.patient.documents.consultation.items.yourself').toString()
			owner = this.$i18n.t('vm.root.patients.patient.documents.consultation.items.by', {owner: !!owner ? owner : yourself}).toString()

			let label: TranslateResult =
				DateHelper.sameDay(today, c.act_date) ?
				this.$i18n.t('vm.root.patients.patient.documents.consultation.items.label-day', {date: this.$i18n.t('general.today'), owner: owner}) :
				DateHelper.sameDay(yesterday, c.act_date) ?
				this.$i18n.t('vm.root.patients.patient.documents.consultation.items.label-day', {date: this.$i18n.t('general.yesterday'), owner: owner}) :
				this.$i18n.t('vm.root.patients.patient.documents.consultation.items.label-date', {date: this.$i18n.d(c.act_date, 'll'), owner: owner})
			return {
				value: c.id,
				label: label.toString()
			}
		})
    }
    get hasSelectedConsultation(): boolean {
        return parseInt(this.con_id) !== -1
    }
    get rangeType(): InputRangeType.RangeTime {
		return InputRangeType.RangeTime
	}
	get range(): Date[] {
		this.start = this.consultation.start_schedule
		this.end = this.consultation.end_schedule
		return [this.start, this.end]
	}
	set range(range: Date[]) {
		this.start = range[0]
		this.end = range[1]
	}
	private get consultation(): IConsultation {
        return this.consultations.find(c => c.id === this.con_id)
    }
	//#endregion

	mounted() {
		this.con_id = !!this.$route.params.con_id ? this.$route.params.con_id : '-1'
		this.$emit('navigate', 'documents')
		this.start = new Date()
		this.start.setMinutes(this.start.getMinutes() - (this.start.getMinutes() % 5))
		this.end = new Date(this.start)
		this.end.setMinutes(this.end.getMinutes() + 45)

		this.initializeBreadcrumb()
	}

	//#region InvoiceManager implementation
	updateConsultationId(conId: string): void {
		this.con_id = conId
	}
	//#endregion

	owner(item: {id: string, creator_id: string}): string {
		return patient.owner(item)
	}

	invoicePrinted(): boolean {
		return this.getInvoicePrinted(this.consultation)
	}

	invoiceSended(): boolean {
		return this.getInvoiceSended(this.consultation)
	}

	invoiceTransmitted(): boolean {
		return this.getInvoiceTransmitted(this.consultation)
	}

	printAttestation(): void {
		let label: string = `${this.coordinates.last_name}-${this.coordinates.first_name}`.replace(/\s+|[,\/]/g, "-")

		let filter: Record<string, string> = {}
		if (this.isDemo) filter['demo'] = "true"
		filter['start'] = this.$i18n.d(this.start, 'LT')
		filter['end'] = this.$i18n.d(this.end, 'LT')
		let params:string = new URLSearchParams(filter).toString()

		let url: string = `${config.api_url}/${config.api_version}/${config.api_key}/print/attestation/${this.consultation.id}/${label}.pdf?${params}`
		window.open(url, '_blank').focus()
	}

	updateReceipt(): void {
		let action: IInvoiceAction = {
			action: null,
			title: this.$t('managers.invoice-manager.generate-invoice.action-title').toString(),
      		label: this.$t('managers.invoice-manager.generate-invoice.action-label').toString()
		}

		this.createInvoice(() => { super.updateReceipt(this.consultation) }, action)
	}

	createAndPrint(): void {
		let action: IInvoiceAction = {
			action: () => { super.print(this.consultation).then(() => { this.$forceUpdate() }) },
			title: this.$t('managers.invoice-manager.print-invoice.title').toString(),
			label: this.$t('managers.invoice-manager.print-invoice.label-short').toString(),
		}
		this.createInvoice(action.action, action)
	}

	createAndSend(): void {
		let action: IInvoiceAction = {
			action: () => { super.send(this.consultation).then(() => { this.$forceUpdate() }) },
			title: this.$t('managers.invoice-manager.send-invoice.title').toString(),
			label: this.$t('managers.invoice-manager.send-invoice.label-short').toString(),
		}
		this.createInvoice(action.action, action)
	}

	async createAndTransmit(): Promise<void> {
		if (!this.disabled) {
			let notification: INotification = {message: this.$i18n.t('vm.root.patients.patient.aside-consultation.disabled').toString(), actions:[], delay: 5000, canClose: true}
			notif.info(notification)
			return
		}
		if (!super.currentUser.can_transmit && !await user.canTransmit()) {
			let action: INotificationAction = {
				callback: () => { this.activeUserTransmit() },
				label: this.$t('vm.root.patients.patient.aside-consultation.user-active-transmit.label').toString(),
			}

			let notification: INotification = {message: this.$i18n.t('vm.root.patients.patient.aside-consultation.user-active-transmit.description').toString(), actions:[action], delay: false, canClose: true}
			notif.warning(notification)

			return
		}
		if (!super.patient.can_transmit && !await patient.canTransmit({pat_id: super.patient.id})) {
			let action: INotificationAction = {
				callback: () => { this.activePatientTransmit() },
				label: this.$t('vm.root.patients.patient.aside-consultation.patient-active-transmit.label').toString(),
			}

			let notification: INotification = {message: this.$i18n.t('vm.root.patients.patient.aside-consultation.patient-active-transmit.description').toString(), actions:[action], delay: false, canClose: true}
			notif.warning(notification)

			return
		}
		let action: IInvoiceAction = {
			action: () => { super.transmit(this.consultation).then(() => { this.$forceUpdate() }) },
			title: this.$t('managers.invoice-manager.transmit-invoice.title').toString(),
			label: this.$t('managers.invoice-manager.transmit-invoice.label-short').toString(),
		}
		this.createInvoice(action.action, action)
	}

    addConsultation(): void {
		this.$emit('edit')
		let createId: string = Common.generateId()
		this.consultations.push(Patient.createNewConsultation(createId, this.configuration, Patient.getLastMeasurement(this.consultations)))

		this.$router.push({name:'patient-consultation', params:{pat_id: this.$route.params.pat_id, item_id: createId}})
	}

	private createInvoice(existAction: () => void, action: IInvoiceAction): void {
		this.hasInvoice ? existAction() : super.createReceipt(this.consultation, action)
	}

	private activePatientTransmit(): void {
		patient.activeTransmit({pat_id: super.patient.id})
			.then((url) => {
				let medSmartPopin: IPopin = {
					title: `Connexion à MedSmart`,
					content: {component: 'popin-medsmart'},
					options: {url: url},
					isWide: true,
					cancelLabel: this.$i18n.t('general.close.text').toString(),
				}

				popIn.info(medSmartPopin)
			})
			.catch((error) => { notif.error(error) })
	}

	private activeUserTransmit(): void {
		user.activeTransmit()
			.then((url) => {
				let medSmartPopin: IPopin = {
					title: `Connexion à MedSmart`,
					content: {component: 'popin-medsmart'},
					options: {url: url},
					isWide: true,
					cancelLabel: this.$i18n.t('general.close.text').toString(),
				}

				popIn.info(medSmartPopin)
			})
			.catch((error) => { notif.error(error) })
	}

	// private print(): void {
	// 	let url: string = this.invoicePath
	// 	window.open(url, '_blank').focus()
	// }

	private isManaged(consultation: IConsultation): boolean {
		if (consultation.creator_id === this.currentUser.id) return true

		return this.currentUser.links.filter(u => { return u.id === consultation.creator_id }).length > 0
	}

	private initializeBreadcrumb(): void {
        let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patients').toString(), link: "patients"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patient').toString(), link: {name: "patient-coordinates", params: this.$route.params} }
		let item3: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.documents').toString() }
		breadcrumb.updateItems([item1, item2, item3])
	}
}
