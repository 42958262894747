import Render from '@Views/root/patients/patient/consultation/aside-consultation.html'
import {Component, Prop, Watch} from 'vue-property-decorator'
import {INotification, IConsultation, IDisplay, INotificationAction, IPopin} from '@Store/types'
import { IInvoiceAction, InvoiceManager } from '@Managers/index'
import {configuration, notif, patient, popIn, user} from '@Store/modules'

@Render
@Component({})
export class ASideConsultation extends InvoiceManager {

  hasInvoice: boolean = false
  canBillInvoice: boolean = false;
  canSendInvoice: boolean = true;
  isInvoiceTransmitted: boolean = false;

  @Prop() readOnly: boolean
  @Prop() disabled: boolean

  constructor() {
		super()
  }

	//#region getter
	get isMainUser(): boolean {
		return user.isMainUser
	}
	get consultation(): IConsultation {
		let consultation: IConsultation = patient.consultation(this.$route.params.item_id)
		this.updateHasInvoice(consultation)
		return consultation
	}
	get display(): IDisplay {
		return configuration.configuration && configuration.configuration.display
	}
	get hasFollowUpUnread(): boolean {
		return !!this.consultation.follow_up && !!this.consultation.follow_up.back && !this.consultation.follow_up.read
	}
	get invoiceNumber(): string {
		return this.getInvoiceNumber(this.consultation)
	}
	get canActiveMedSmart(): boolean {
	    return this.currentUser.is_medsmart_active;
	}



	//#endregion

	async mounted() {
		this.updateHasInvoice(this.consultation)
		this.canBill()
		await this.updateInvoiceStatus()
	}


	//#region InvoiceManager implementation
	@Watch('consultation.usr_id')
	canBill(): void {
		this.canBillInvoice = user.currentUser.can_bill;
	}
	updateConsultationId(conId: string): void {
		if (!!conId && parseInt(conId) === -1) {
		  this.hasInvoice = false
		} else {
		  this.updateHasInvoice(this.consultation)
		}
  	}
	//#endregion

	invoicePrinted(): boolean {
		return this.getInvoicePrinted(this.consultation)
	}

	invoiceSended(): boolean {
		return this.getInvoiceSended(this.consultation)
	}

	invoiceTransmitted(): boolean {
		return this.getInvoiceTransmitted(this.consultation)
	}

	async updateInvoiceStatus(): Promise<void> {
		const invoiceId = this.getInvoiceId(this.consultation);

		if (!invoiceId) {
		   return
    	}
		const [canSend, isTransmitted] = await Promise.all([
			this.checkIfInvoiceCanBeSent(invoiceId),
			this.checkIfInvoiceIsTransmitted(invoiceId),
		]);

		this.canSendInvoice = canSend
		this.isInvoiceTransmitted = isTransmitted

	}

	updateReceipt(): void {
		let action: IInvoiceAction = {
		  action: null,
		  title: this.$t('managers.invoice-manager.generate-invoice.action-title').toString(),
		  label: this.$t('managers.invoice-manager.generate-invoice.action-label').toString()
		}

		this.createInvoice(() => { super.updateReceipt(this.consultation) }, action)
	}

	createAndPrint(): void {
		let action: IInvoiceAction = {
		  action: () => { super.print(this.consultation).then(() => { this.$forceUpdate() }) },
		  title: this.$t('managers.invoice-manager.print-invoice.title').toString(),
		  label: this.$t('managers.invoice-manager.print-invoice.label-short').toString(),
		}
		this.createInvoice(action.action, action)
	}

	createAndSend(): void {
		let action: IInvoiceAction = {
		  action: () => { super.send(this.consultation).then(() => { this.$forceUpdate() }) },
		  title: this.$t('managers.invoice-manager.send-invoice.title').toString(),
		  label: this.$t('managers.invoice-manager.send-invoice.label-short').toString(),
		}
		this.createInvoice(action.action, action)
	}

	async createAndTransmit(): Promise<void> {
		if (!this.disabled) {
			let notification: INotification = {message: this.$i18n.t('vm.root.patients.patient.aside-consultation.disabled').toString(), actions:[], delay: 5000, canClose: true}
			notif.info(notification)
			return
		}
		if (!super.currentUser.can_transmit && !await user.canTransmit()) {
			let action: INotificationAction = {
				callback: () => { this.activeUserTransmit() },
				label: this.$t('vm.root.patients.patient.aside-consultation.user-active-transmit.label').toString(),
			}

			let notification: INotification = {message: this.$i18n.t('vm.root.patients.patient.aside-consultation.user-active-transmit.description').toString(), actions:[action], delay: false, canClose: true}
			notif.warning(notification)

			return
		}
		if (!super.patient.can_transmit && !await patient.canTransmit({pat_id: super.patient.id})) {
			let action: INotificationAction = {
				callback: () => { this.activePatientTransmit() },
				label: this.$t('vm.root.patients.patient.aside-consultation.patient-active-transmit.label').toString(),
			}

			let notification: INotification = {message: this.$i18n.t('vm.root.patients.patient.aside-consultation.patient-active-transmit.description').toString(), actions:[action], delay: false, canClose: true}
			notif.warning(notification)

			return
		}

		if (!this.canSendInvoice && !this.isInvoiceTransmitted) {
			let notification: INotification = {message: this.$i18n.t('vm.root.patients.patient.aside-consultation.invoice-transmit-processing').toString(), actions:[], delay: 5000, canClose: true}
			notif.info(notification)
			return
		}

		if (this.isInvoiceTransmitted) {
			let notification: INotification = {message: this.$i18n.t('vm.root.patients.patient.aside-consultation.invoice-transmit-success').toString(), actions:[], delay: 5000, canClose: true}
			notif.info(notification)
			return
		}

		let action: IInvoiceAction = {
			action: () => { super.transmit(this.consultation).then(() => {
				this.$forceUpdate()
				this.updateInvoiceStatus()
			}) },
			title: this.$t('managers.invoice-manager.transmit-invoice.title').toString(),
			label: this.$t('managers.invoice-manager.transmit-invoice.label-short').toString(),
		}
		this.createInvoice(action.action, action)
	}

	submitDelete() {
		patient.deleteConsultation(this.consultation)
		this.$router.push({name: "patient-consultations", params: this.$route.params})
	}

	private activePatientTransmit(): void {
		patient.activeTransmit({pat_id: super.patient.id})
			.then((url) => {
				let medSmartPopin: IPopin = {
					title: `Connexion à MedSmart`,
					content: {component: 'popin-medsmart'},
					options: {url: url},
					isWide: true,
					cancelLabel: this.$i18n.t('general.close.text').toString(),
				}

				popIn.info(medSmartPopin)
			})
			.catch((error) => { notif.error(error) })
	}

	private activeUserTransmit(): void {
		user.activeTransmit()
			.then((url) => {
				let medSmartPopin: IPopin = {
					title: `Connexion à MedSmart`,
					content: {component: 'popin-medsmart'},
					options: {url: url},
					isWide: true,
					cancelLabel: this.$i18n.t('general.close.text').toString(),
				}

				popIn.info(medSmartPopin)
			})
			.catch((error) => { notif.error(error) })
	}

	private createInvoice(existAction: () => void, action: IInvoiceAction): void {
		if (this.disabled) {
			this.hasInvoice ? existAction() : super.createReceipt(this.consultation, action)
		} else {
			let notification: INotification = {message: this.$i18n.t('vm.root.patients.patient.aside-consultation.disabled').toString(), actions:[], delay: 5000, canClose: true}
			notif.info(notification)
		}
	}

	private updateHasInvoice(consultation: IConsultation): void {
		let invId: string = this.getInvoiceId(consultation)
		this.hasInvoice = !!invId && parseInt(invId) !== -1
	}
}
