import { BaseService } from './base.service'

export class MedsmartService extends BaseService {

	public async createMedSmartPs(): Promise<any> {
		return super.post('/medsmart/create-ps')
		.then(data => {
			return Promise.resolve(data)
		})
		.catch((error: any) => {
			return Promise.reject("La connexion avec les serveurs Medsmart est interrompue. Veuillez réessayer plus tard.")
		})
	}


	public async medSmartPsCanTransmit(): Promise<boolean> {
		return super.get(`medsmart/ps-can-transmit`)
			.then(data => {
				return Promise.resolve(data.can_transmit)
			})
			.catch(() => {
				return Promise.reject(false)
			})
	}

	public async createMedSmartPatient(patId: string): Promise<any> {
		return super.post(`medsmart/create-patient/${patId}`)
		.then(data => {
			return Promise.resolve(data)
		})
		.catch(() => {
			return Promise.reject("La connexion avec les serveurs Medsmart est interrompue. Veuillez réessayer plus tard.")
		})
	}

	public async medSmartPatientCanTransmit(patId: string): Promise<boolean> {
		return super.get(`medsmart/patient-can-transmit/${patId}`)
		.then(data => {
			return Promise.resolve(data.can_transmit)
		})
		.catch(() => {
			return Promise.reject(false)
		})
	}
}
