<context>
	<infinite-scroll :initialized="initialized" tag="hr" class-tag="wrapper" :load-more="loadNextInvoices" :is-loading="isLoading" :can-load-more="hasMoreInvoices">
		<section class="wrapper marg-v">
			<h2 ref="invoiceTitle">
				<svg width="1em" class="svg-middle" viewBox="0 0 576 512" aria-hidden="true" focusable="false">
					<use xlink:href="@Assets/svg/symbol-defs.svg#shopping-cart"/>
				</svg>
				{{ $t('vm.root.shop.context.title') }}
			</h2>

			<p v-if="!invoiceList || invoiceList.length === 0">{{ $t('vm.root.shop.context.none') }}</p>

			<div v-else v-for="(invoice, index) in invoiceList" :key="invoice.id" >
		         <invoice-item :invoice="invoice"/>
				<hr v-if="index !== invoiceList.length - 1">
			</div>

		</section>

		<div slot="loading" class="text-center">
			<spinner class="svg-xl text-primary"/>
		</div>
	</infinite-scroll>

	<control-bar class="text-left" v-if="showScrollTop">
		<button
			@click="scrollToTop"
			class="btn btn-default"
			:title="$t('vm.components.button-to-top.label')"
		>
			<svg width="1em" class="svg-middle" viewBox="0 0 448 512" aria-hidden="true" focusable="false">
				<use xlink:href="@Assets/svg/symbol-defs.svg#arrow-alt-up"/>
			</svg>
		</button>
		&nbsp;
	</control-bar>

</context>
