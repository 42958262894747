import Render from '@Views/root/help/new-ticket.html'
import { Vue, Component } from 'vue-property-decorator'
import { IBreadcrumbItem, IUser, ITicket, IComment } from '@Store/types'
import { TicketState, RequestStatus } from '@Enums/index'
import { breadcrumb, support, user } from '@Store/modules'

@Render
@Component({})
export class NewTicket extends Vue {
	
	submitted: boolean = false
	title: string = ''
	comment: string = ''

	// @State('user') stateUser: UserState
	// @State('support') supportState: SupportState
	// @Mutation('breadcrumb/updateItems') updateItems: (items: IBreadcrumbItem[]) => void
	// @Action('support/createTicket') createTicket: (ticket: ITicket) => any

	get user(): IUser {
		return user.user
	}
	get isPosting() {
		return support.statusCreating === RequestStatus.Loading
	}
	get hasTitleError(): boolean {
		return this.submitted && !this.title
	}
	get hasCommentError(): boolean {
		return this.submitted && !this.comment
	}
	get canCreateTicket(): boolean {
		return !this.user.isSubscriptionRequired || (!!this.user.support_date && this.user.support_date > new Date())
	}

	mounted() {
		this.initializeBreadcrumb()
	}

	submitPost(): void {
		this.submitted = true
		this.postComment()
	}

	mainDate(date: Date): string {
		if (!date) return ''
		return date.toISOString().split('T')[0]
	}

	private postComment() {
		if (!this.canPost()) return

		let comment: IComment =  {content: this.comment, admin: false, date_create: new Date}
		let ticket: ITicket = {id: '-1', title: this.title, comments:[comment], unread_count: 0, state: TicketState.Start}
		support.createTicket(ticket)
		.then(newTicket => {
			let route = {name: 'ticket', params: {sup_id: newTicket.id}}
			this.$router.push(route)
		})
	}

	private canPost(): boolean {
		return !!this.title && !!this.comment
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.help').toString(), link: "help" }
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.new-ticket').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}
