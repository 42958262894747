import Render from '@Views/root/help/aside-support.html'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { IPopin, IPopinAction, ITicket } from '@Store/types'
import { PackType } from '@Enums/index'
import { popIn, support, user } from '@Store/modules'

@Render
@Component({})
export class ASideSupport extends Vue {

	private withLoading: boolean = false

	get tickets(): ITicket[] {
		return support.ticketsOpened
	}
	get closedCount(): number {
		return support.count.closed_count || 0
	}
	get unreadClosedCommentCount(): number {
		return support.unreadClosedCommentCount
	}
	get unreadCommentCount(): number {
		return support.unreadCommentCount
	}
	get isLoading(): boolean {
		return this.withLoading && support.isOpenedLoading
	}
	private get hasValidSupport(): boolean {
		return !user.user.isSubscriptionRequired || this.supportDate >= new Date()
	}
    private get supportDate(): Date {
        return user.user.support_date || new Date()
    }

	mounted() {
		this.updateTickets(true)
	}

	newTicket() {
		if (this.hasValidSupport) {
			this.$router.push({name: 'new-ticket'})
		} else {
			this.askSupport()
		}
	}

	@Watch('unreadCommentCount')
	@Watch('unreadClosedCommentCount')
	private updateTickets(withLoading: boolean = false) {
		this.withLoading = withLoading
		support.loadOpenedTickets()
	}

	private askSupport(): void {
		let supportAction: IPopinAction = {
			label: this.$i18n.t('vm.root.help.aside.support.label').toString(),
			title: this.$i18n.t('vm.root.help.aside.support.title').toString(),
			callback: (data: any) => {
				this.$router.push({name: 'purchase-pack', params:{pack: (PackType.Support as number).toString()}})
				return Promise.resolve()
			}
		}

		let help: IPopin = {
			title: this.$i18n.t('vm.root.help.aside.support.title').toString(),
			content: this.$i18n.t('vm.root.help.aside.support.content').toString(),
			action: supportAction,
			options: {}
		 }

		popIn.error(help)
	}
}
