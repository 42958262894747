import Render from '@Views/root/shop/context-main.html'
import {Component, Vue} from 'vue-property-decorator'
import {IStripeInvoice} from '@Store/types'
import { shop } from '@Store/modules'
import { InfiniteScroll } from "@ViewModels/components"
import {InvoiceItem} from "@ViewModels/root/shop/invoice-item";

@Render
@Component({
	components: {
		'infinite-scroll': InfiniteScroll,
		'invoice-item' : InvoiceItem
	}
})
export class ContextMain extends Vue {

    static invoicePerPage: number = 12

	initialized: boolean = true
	hasMoreInvoices: boolean = true
	showScrollTop: boolean = false
	private page: number
	private observer: IntersectionObserver | null = null;

	//#region Getters
	get isLoading(): boolean {
		return shop.isInvoicesLoading
	}

	get invoiceList(): IStripeInvoice[] {
		return shop.invoices
	}
	//#endregion

	mounted() {
		this.page = 0
		shop.clearInvoices()
		this.loadNextInvoices()
		this.$nextTick().then(()=>{
			this.listenScrollEvent()
		})
	}
	unmounted() {
		this.observer.disconnect()
	}

	loadNextInvoices(): void {
		shop.loadInvoices(this.page)
		.then((invoices: IStripeInvoice[]) => {
			this.hasMoreInvoices = invoices.length >= ContextMain.invoicePerPage
			this.page++
			this.initialized = false
		})
	}
	listenScrollEvent(){
		const options = {
			root: null,
		};
	     this.observer = new IntersectionObserver((entries)=>{
			   for(const entry of entries){
				   this.showScrollTop = !entry.isIntersecting;
			   }
		},options);

		let target = this.$refs['invoiceTitle'] as Element;
		this.observer.observe(target)
	}

	scrollToTop(){
		let target = this.$refs['invoiceTitle'] as Element;
		target.scrollIntoView({ behavior: 'smooth', block: 'start' })
	}

}
