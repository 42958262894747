<div>
    <div v-if="isUserSubscribed">
		<p>
			Comme vous avez pu le lire depuis le 1er septembre, <strong>Osteo2ls et son modèle économique
			évoluent</strong>. Dans l'optique de pérenniser le logiciel et de nous permettre de réaliser les
			investissements qui s'imposent, Osteo2ls passe au mode abonnement.
		</p>
		<p>
			Cet abonnement inclura deux nouveaux modules pour tout le monde : <strong>le planning en ligne</strong>,
			permettant à vos patients de prendre rendez-vous avec vous via une page Internet dédiée,
			et <strong>le support utilisateur</strong>, vous permettant de nous poser vos questions via la page Support.
		</p>
		<p>
			Pour vous, client historique, et vous remercier de votre confiance, <strong>vous bénéficiez d'une
			réduction de 50 % sur le tarif public de l'abonnement</strong>.
		</p>
		<p class="notice-info text-left">
			Le bouton <strong><em>"Je choisis plus tard"</em></strong> ne sera plus disponible après le 1er janvier 2025.<br>
			Si vous vous abonnez maintenant, vous ne serez prélevé qu'à partir du {{ paymentDate }}.
		</p>
		<radio-frame id="choice-1" key="choice-subscription-1" v-model="choice"
					 default-class="p-like" name="choice-1" title="Choix 1"
					 radio-value="choice-1">Je m'abonne avec 50 % de réduction</radio-frame>
		<radio-frame id="choice-2" key="choice-subscription-2" v-model="choice"
					 default-class="p-like" name="choice-2" title="Choix 2"
					 radio-value="choice-2">Je ne m'abonnerai pas</radio-frame>

		<div v-if="choice === 'choice-1'">
			<p>Veuillez sélectionner le type d'abonnement que vous souhaitez, prélèvement annuel ou mensuel</p>

			<dropdown-frame id="subscription" v-model="subscription" :items="subscriptionItems"
							:label="$t('vm.components.popin.subscription.subscription.label')"
							:title="$t('vm.components.popin.subscription.subscription.title')"></dropdown-frame>

		</div>
		<div v-else-if="choice === 'choice-2'" class="notice-warning text-left">
			<p>
				Attention, en cas de non abonnement, vous pourrez encore accéder à Osteo2ls jusqu'au {{ expirationDate }}.<br>
				Après cette date, il ne sera plus possible d'accéder à la version en ligne d'Osteo2ls.
			</p>
			<p>
				Vous avez encore jusqu'au 1er janvier 2025 pour vous décider, et revenir en arrière sur votre décision.
				Passé le 1er janvier 2025, votre décision de ne pas vous abonner sera définitive, et vous ne pourrez plus
				bénéficier de la remise client de 50%.
			</p>
			<div class="p-like">
				<i18n path="vm.login.register.cgv.text" tag="checkbox-frame" id="cgv" v-model="cgv">
					<a href="//osteo2ls.com/logiciel/assets/mentions-legales.pdf" :title="$t('vm.login.register.cgv.link_title')">{{ $t("vm.login.register.cgv.link") }}</a>
				</i18n>
			</div>

		</div>
	</div>
  <div v-else>
	  <p>Désolé, ce message n'aurait pas du s'afficher</p>
  </div>
</div>
