<div v-if="options">
	<div class="iframe-container">
		<iframe
			:src="url"
			allow="camera; microphone;"
			sandbox="allow-scripts allow-same-origin allow-top-navigation"
			referrerPolicy="origin"
			@load="frameLoaded"></iframe>
	</div>
</div>
