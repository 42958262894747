export const invoiceItem = {
    title: "Télécharger la facture",
    invoice: "aucune facture | une facture | {count} factures",
    'not-finalized': "achat non finalisé",
	'subscription-date': "Acheté le :",
	'invoice-download-text': 'télécharger la facture',
	'invoice-download-title': 'Télécharger la facture',
	'in-progress': 'Paiement en cours de traitement',
	unpaid: 'Paiement non finalisé',
	"pack-title": "Achat Pack {pack} {key}",
	"monthlySubscription": "Abonnement Mensuel",
	"annualSubscription": "Abonnement Annuel"
}
