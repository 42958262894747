import Render from '@Views/root/shop/invoice-item.html'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {IStripeInvoice} from '@Store/types'
import {InvoiceMode, InvoiceState} from "@Enums/purchase";

@Render
@Component({})
export class InvoiceItem extends Vue {

	@Prop() invoice: IStripeInvoice
	private readonly STRIPE_PACK_IMPORT:string = `IMPORT`
	get title(): string {
		if (this.invoice.mode === InvoiceMode.Payment) {
			return this.$i18n.t('vm.root.shop.invoice-item.pack-title', {
				pack: this.invoice.meta_key !== this.STRIPE_PACK_IMPORT ? this.invoice.meta_value : '',
				key:  this.invoice.meta_key
			}).toString();
		}
		return this.invoice.meta_value === 1
			? this.$i18n.t('vm.root.shop.invoice-item.monthlySubscription').toString()
			: this.$i18n.t('vm.root.shop.invoice-item.annualSubscription').toString();
	 }

	get formattedDate(): string {
		if (this.invoice.session_date) {
			return new Date(this.invoice.session_date).toLocaleDateString("fr-FR", {
				year: "numeric",
				month: "long",
				day: "numeric",
			});
		}
		return new Date().toLocaleDateString('fr-FR', {
			year: "numeric",
			month: "long",
			day: "numeric",
		});
	}

	get isPaid(): boolean {
		return this.invoice.session_state === InvoiceState.Paid
	}

	get isInProgress(): boolean {
		return this.invoice.session_state === InvoiceState.Inprogress;
	}

	get isUnpaid(): boolean {
		return this.invoice.session_state === InvoiceState.Unpaid;
	}

}
