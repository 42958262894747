import Render from '@Views/root/user/account/informations.html'
import { Vue, Component, Prop } from 'vue-property-decorator'
import {IBreadcrumbItem, INotification, IPopin, IUser} from '@Store/types'
import { IToggleBoxItem } from '@Components/index'
import { User } from '@Models/index'
import {breadcrumb, notif, popIn, user} from '@Store/modules'
import {Gender} from "@Enums/gender";

@Render
@Component({})
export class Informations extends Vue {

	private _hasAdeliError: boolean = false
	@Prop() submitted: boolean
	@Prop() user: IUser

	//#region Getters
	get canTransmit(): boolean {
		return this.user && this.user.can_transmit
	}
	get canActiveMedSmart(): boolean{
		return this.user.is_medsmart_active;
	}
	get hasLastNameError(): boolean {
		return this.submitted && !this.user.coordinates.last_name
	}
	get hasFirstNameError(): boolean {
		return this.submitted && !this.user.coordinates.first_name
	}
	get hasSirenError(): boolean {
		return this.submitted && !this.hasValidSiren
	}
	get isLoading(): boolean {
		return user.isLoading
	}
	get isTransmitting(): boolean {
		return user.isTransmitting
	}
	get genderItems(): IToggleBoxItem[] {
		return [
			{value: Gender.Man, label: this.$i18n.t('vm.root.user.informations.gender.man').toString(), icon:"mars"},
			{value: Gender.Woman, label: this.$i18n.t('vm.root.user.informations.gender.woman').toString(), icon:"venus"},
			{value: Gender.Other, label: this.$i18n.t('vm.root.user.informations.gender.other').toString()}
		]
	}
	private get hasValidSiren(): boolean {
		return !this.user.coordinates.siren || User.isLuhnNum(this.user.coordinates.siren)
	}
	//#endregion

	async mounted() {
		this.$emit('navigate', 'account')
		this.initializeBreadcrumb()
		if (this.canActiveMedSmart && !this.canTransmit) {
			await user.invokeMedSmartCallbackPs();
		}
	}

	hasAdeliError(): boolean {
		return this._hasAdeliError
	}


	async activeMedSmart(): Promise<void> {
		if (this.canTransmit) {
			let notification: INotification = {message: this.$i18n.t('vm.root.user.informations.transmit.medsmart-actived').toString(), actions:[], delay: 5000, canClose: true}
			notif.warning(notification)
			return
		} else {
			this._hasAdeliError = false
			this.$forceUpdate()
			user.activeTransmit()
				.then((url) => {
					let medSmartPopin: IPopin = {
						title: `Connexion à MedSmart`,
						content: {component: 'popin-medsmart'},
						options: {url: url},
						isWide: true,
						cancelLabel: this.$i18n.t('general.close.text').toString(),
					}

					popIn.info(medSmartPopin)
				})
				.catch((error) => {
					if (!!this.user.coordinates.adeli && this.user.coordinates.adeli.trim().length > 0) {
						notif.error(error)
					} else {
						this._hasAdeliError = true
						this.$forceUpdate()
					}
				})
		}


	}

	private initializeBreadcrumb(): void {
		let item: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.user').toString() }
		breadcrumb.updateItems([item])
	}
}
