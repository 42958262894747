import { getModule, VuexModule, Module, Action, Mutation } from "vuex-module-decorators"
import { store } from '@Store/index'
import {IShop, IStripeInvoice} from "@Store/types"
import { RequestStatus } from "@Enums/index"
import { PurchaseService } from "@Services/index"
import {InvoiceService} from "@Services/shop/invoice.service";

@Module({
	name: 'shop',
	store: store,
	namespaced: true,
	stateFactory: true,
	dynamic: true
})
class ShopModule extends VuexModule {
	shop: IShop = null
	invoices: IStripeInvoice[] = []
    private status: RequestStatus = RequestStatus.None
    private statusShop: RequestStatus = RequestStatus.None
    private statusList: RequestStatus = RequestStatus.None

	get isInvoicesLoading(): boolean {
		return this.statusList === RequestStatus.Loading
	}

	@Mutation
	clear() {
		this.status = RequestStatus.None
		this.statusShop = RequestStatus.None
		this.statusList = RequestStatus.None
		this.shop = null
		this.invoices = []
	}

	@Mutation
	shopRequest() {
		this.status = RequestStatus.Loading
	}

	@Mutation
	shopSuccess(shop: IShop) {
		this.status = RequestStatus.Success
		this.shop = shop
	}

	@Mutation
	shopFailure() {
		this.status = RequestStatus.Failed
	}

	@Mutation
	invoicesRequest() {
		this.statusList = RequestStatus.Loading
	}
	@Mutation
	invoicesSuccess(invoices: IStripeInvoice[]) {
		this.statusList = RequestStatus.Success
		this.invoices = [...this.invoices, ...invoices]
	}
	@Mutation
	invoicesFailure() {
		this.statusList = RequestStatus.Failed
	}

	@Mutation
	clearInvoices() {
		this.statusList = RequestStatus.None
		this.invoices = []
	}
	@Action({rawError: true})
	async loadShop(): Promise<any> {
		if (this.statusShop === RequestStatus.Loading) return

		this.shopRequest()

		let service = new PurchaseService()
		return service.getShop()
		.then(shop => {
			this.shopSuccess(shop)
			return Promise.resolve(shop)
		})
		.catch(error => {
			this.shopFailure()
			return Promise.reject(error)
		})
	}

	@Action({rawError: true})
	async loadInvoices(page: number): Promise<any> {
		if (this.status === RequestStatus.Loading) return

		this.invoicesRequest()

		let service = new InvoiceService()
		return service.getInvoiceList(page)
			.then(
				invoices => {
					this.invoicesSuccess(invoices)
					return Promise.resolve(invoices)
				}
			)
			.catch(error => {
				this.invoicesFailure()
				return Promise.reject(error)
			})
	}
}

export const shop = getModule(ShopModule)

// export const shop = new ShopModule({ store, name: "shop" })
