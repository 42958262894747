export const aside = {
    back: {
        text: "Retour aux patients",
        title: "Retournez à votre liste de patient"
    },
    coordinates: {
        text: "Informations générales",
        title: "Modifiez les coordonnées de votre patient"
    },
    antecedents: {
        text: "Antécédent | Antécédent | Antécédents",
        title: "Modifiez les antécédents de votre patient",
        family: "{count} familial | {count} familial | {count} familiaux"
    },
    spheres: {
        text: "Sphère | Sphère | Sphères",
        title: "Modifiez les sphères de votre patient"
    },
    consultations: {
        text: "Consultation | Consultation | Consultations",
        title: "Modifiez les consultations de votre patient"
    },
    attachments: {
        text: "Pièce jointe | Pièce jointe | Pièces jointes",
        title: "Modifiez les pièces jointes de votre patient"
    },
    pediatrics: {
        text: "Périnatalité",
        title: "Modifiez les éléments liés à la périnatalité de votre patient"
    },
    family: {
        text: "Famille",
        title: "Renseignez les membres de la famille de votre patient"
    },
    relaunch: {
        text: "Relance préventive",
        title: "Relance à titre préventif"
    },
    documents: {
        text: "Facture et attestation",
        title: "Les documents liés à ce patient"
    },
    whitelist: {
        label: "Whitelister ce patient",
        text: "Ce patient <strong>n'est plus blacklisté</strong>."
    },
    blacklist: {
        label: "Blacklister ce patient",
        text: "Ce patient est désormais <strong>blacklisté</strong>."
    },
    resurrected: {
        label: "Patient ressuscité",
        text: "Ce patient <strong>n'est plus décédé</strong>."
    },
    deceased: {
        label: "Patient décédé",
        text: "Ce patient est désormais <strong>décédé</strong>."
    },
    delete: {
        text: "Supprimer ce dossier",
        title: "Supprimer ce dossier patient",
        message: "Voulez-vous vraiment supprimer ce dossier ?"
    },
    deleting: "Le dossier de ce patient sera définitivement effacé dans {count} secondes. | Le dossier de ce patient sera définitivement effacé dans {count} seconde. | Le dossier de ce patient sera définitivement effacé dans {count} secondes.",
    deleted: {
        succeeded: "Le patient a été définitivement effacé.",
        failed: "Le patient n'a pas pu être effacé. Veuillez <strong>réessayer plus tard</strong>."
    }
}
