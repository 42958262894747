export const subscription = {
    accept : "J'ai bien lu, ne plus afficher ce message.",
	subscription: {
		label: "Choix de l'abonnement",
		title: "Choix de l'abonnement",
	},
	'subscription-items': {
		'annually': 'Abonnement annuel 144 € TTC / an (soit 12 € TTC / mois)',
		'monthly': 'Abonnement mensuel 14,5 € TTC / mois (sans engagement)'
	}
}
