import Render from '@Views/root/patients/patient/coordinates.html'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ReadOnlyCoordinates } from './read-only-coordinates'
import { InputDateType, IPhone, IToggleBoxItem } from '@Components/index'
import {Gender, PhoneType} from '@Enums/index'
import { Debouncer, Tag } from '@Models/index'
import {
	IUser,
	INotification,
	INotificationAction,
	ICoordinates,
	IEmail,
	PatientDuplicatePayload,
	IBreadcrumbItem,
	IPatient,
	IForm,
	IPopin
} from '@Store/types'
import { ColleagueItem, SearchColleague } from '@ViewModels/root/search/colleague'
import { Level, Situation } from '@Enums/index'
import { NirHelper, EnumsHelper } from '@Helpers/index'
import { CustomForm } from '@Components/forms/custom-form'
import {breadcrumb, configuration, notif, patient, popIn, user} from '@Store/modules'

@Render
@Component({
	components: {
		'colleague-item': ColleagueItem,
		'search-colleague': SearchColleague,
		'custom-form': CustomForm,
		'read-only': ReadOnlyCoordinates
	}
})
export class Coordinates extends Vue {

	config: any
	levelItems: IToggleBoxItem[] = [
		{value: Level.Low, label: EnumsHelper.levelToString(Level.Low)},
		{value: Level.Medium, label: EnumsHelper.levelToString(Level.Medium), class: 'is-warning'},
		{value: Level.High, label: EnumsHelper.levelToString(Level.High), class: 'is-danger', icon:"exclamation-triangle"}
	]
	defaultPhone: IPhone = {id: '-1', number: '', iso: 'fr', type: PhoneType.Mobil, error: false}
	defaultEmail: IEmail = {id: '-1', address: '', error: false}
	private debouncer: Debouncer
	@Prop() submitted: boolean
	@Prop() readOnly: boolean
	@Prop({}) disabled: boolean

	get isAnonymous(): boolean {
		return configuration.isAnonymous
	}
	get coordinates(): ICoordinates {
		return patient.coordinates
	}
	get canTransmit(): boolean {
		return this.patient && this.patient.can_transmit
	}
	get canActiveMedSmart(): boolean {
		return this.user.is_medsmart_active;
	}
	get hasLastNameError(): boolean {
		return this.submitted && !this.coordinates.last_name
	}
	get hasFirstNameError(): boolean {
		return this.submitted && !this.coordinates.first_name
	}
	get hasBirthDateError(): boolean {
		return this.submitted && !this.coordinates.birth_date
	}
	get birthDateType(): InputDateType {
		return InputDateType.Numpad
	}
	get hasNirError(): boolean {
		return this.submitted && !NirHelper.isValidNumber(this.coordinates.nir.number, this.coordinates.nir.iso)
	}
	get genderItems(): IToggleBoxItem[] {
		return [
			{value: Gender.Man, label: this.$i18n.t('vm.root.patients.patient.coordinates.gender.man').toString(), icon:"mars"},
			{value: Gender.Woman, label: this.$i18n.t('vm.root.patients.patient.coordinates.gender.woman').toString(), icon:"venus"},
			{value: Gender.Other, label: this.$i18n.t('vm.root.patients.patient.coordinates.gender.other').toString()}
		]
	}
	get situationItems(): IToggleBoxItem[] {
		return [
			{value: Situation.None, label: EnumsHelper.situationToString(Situation.None, this.coordinates.gender)},
			{value: Situation.Single, label: EnumsHelper.situationToString(Situation.Single, this.coordinates.gender)},
			{value: Situation.Married, label: EnumsHelper.situationToString(Situation.Married, this.coordinates.gender)},
			{value: Situation.Divorced, label: EnumsHelper.situationToString(Situation.Divorced, this.coordinates.gender)},
			{value: Situation.Widowed, label: EnumsHelper.situationToString(Situation.Widowed, this.coordinates.gender)},
			{value: Situation.FreeUnion, label: EnumsHelper.situationToString(Situation.FreeUnion, this.coordinates.gender)},
			{value: Situation.Pacs, label: EnumsHelper.situationToString(Situation.Pacs, this.coordinates.gender)}
		]
	}
	get childrenItems(): IToggleBoxItem[] {
		let result: IToggleBoxItem[] = [{value: -1, label: this.$i18n.t('vm.root.patients.patient.coordinates.number-of-children.none').toString()}]
		for(let i:number = 0; i <= 15; i++) {
			result.push({value: i, label: this.$i18n.tc('vm.root.patients.patient.coordinates.number-of-children.item', i).toString()})
		}
		return result
	}
	get isTransmitting(): boolean {
		return patient.isTransmitting
	}
	get forms(): IForm[] {
		return this.coordinates.forms
	}
	private get patient(): IPatient {
		return patient.patient
	}
	private get user(): IUser {
		return user.user
	}

	async mounted() {
		this.$emit('navigate', 'coordinates')
		this.debouncer = new Debouncer(this.checkingDuplicate, 500)
		this.initializeBreadcrumb()
		if (this.canActiveMedSmart && !this.canTransmit) {
			await patient.invokeMedSmartCallbackPatient({ pat_id: this.patient.id })
		}
	}

	beforeDestroy() {
		Debouncer.clear(this.debouncer)
	}

	getProfessionList(tag: string): any {
		return Tag.getProfession(tag)
	}

	getReferenceList(tag: string): any {
		return Tag.getReference(tag)
	}

	async activeTransmit(): Promise<void> {
		if (!this.disabled) {
			let notification: INotification = {message: this.$i18n.t('vm.root.patients.patient.coordinates.medsmart-disabled').toString(), actions:[], delay: 5000, canClose: true}
			notif.info(notification)
			return
		}

		if (!user.currentUser.can_transmit && !await user.canTransmit()) {

			let action = () => this.handleActiveTransmitForUser();

			let notification: INotification = {
				message: this.$i18n.t('vm.root.patients.patient.coordinates.user-active-transmit.description').toString(),
				actions:[{
					callback: action,
					label: this.$t('vm.root.patients.patient.coordinates.user-active-transmit.label').toString()
				}],
				delay: false,
				canClose: true
			}

			notif.warning(notification)

			return
		}

		await this.handleActiveTransmitForPatient();

	}

	// Méthode pour gérer la logique de transmission
	private async handleActiveTransmitForPatient(): Promise<void> {
		try {

			const url = await patient.activeTransmit({ pat_id: this.patient.id });

			let medSmartPopin: IPopin = {
				title: `Connexion à MedSmart`,
				content: { component: 'popin-medsmart' },
				options: { url: url },
				isWide: true,
				cancelLabel: this.$i18n.t('general.close.text').toString(),
			};

			popIn.info(medSmartPopin);

		} catch (error) {
			{ notif.error(error) }
		}
	}

	private async handleActiveTransmitForUser(): Promise<void> {
		try {

			const url = await user.activeTransmit();

			let medSmartPopin: IPopin = {
				title: `Connexion à MedSmart`,
				content: {component: 'popin-medsmart'},
				options: {url: url},
				isWide: true,
				cancelLabel: this.$i18n.t('general.close.text').toString(),
			}

			popIn.info(medSmartPopin)

		} catch (error) {
			{
				notif.error(error)
			}
		}
	}

	@Watch('coordinates.last_name')
	@Watch('coordinates.first_name')
	@Watch('coordinates.birth_date')
	canCheckDuplicate() {
		if (!this.patient || !this.patient.is_new) return
		if (!this.coordinates.last_name) return
		if (!this.coordinates.first_name) return
		if (!this.coordinates.birth_date) return

		this.debouncer.start()
	}

	@Watch('coordinates.number_of_children')
	updatePediatrics() {
		if (!this.patient) return
		this.patient.pediatrics.number_of_children = this.coordinates.number_of_children
	}

	private activeUserTransmit(): void {
		user.activeTransmit()
			.then((url) => {
				window.location.href = url
			})
			.catch((error) => { notif.error(error) })
	}

	private checkingDuplicate() {
		let payload: PatientDuplicatePayload = {
			usr_id: this.user.id,
			profile: this.user.profile,
			last_name: this.coordinates.last_name,
			first_name: this.coordinates.first_name,
			birth_date: this.coordinates.birth_date
		}

		patient.checkDuplicate(payload)
		.then(result => {
			if (!result.id) notif.clear()
			else notif.info(this.getNotification(result.id))
		})
	}

	private getNotification(id: string): INotification {
		let openAction: INotificationAction = {
            label: this.$i18n.t('vm.root.patients.patient.coordinates.check-duplicate.action').toString(),
            callback: () => { this.$router.push({name:'patient-coordinates', params: {pat_id: id}}) }
        }
        let notification: INotification = {
            message: this.$i18n.t('vm.root.patients.patient.coordinates.check-duplicate.message').toString(),
            actions: [openAction],
            canClose: true,
            delay: false
		}

		return notification
	}

	private initializeBreadcrumb(): void {
		let item1: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patients').toString(), link: "patients"}
		let item2: IBreadcrumbItem = { label: this.$i18n.t('vm.root.breadcrumb.patient').toString() }
		breadcrumb.updateItems([item1, item2])
	}
}
